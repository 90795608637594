<template>
    <Table
        :isLoading="isLoading"
        :columnConfig="tableColumnConfig"
        :rows="tableShowData"
        :excelConfig="excelConfig"
        :toolbox="['columnSetting', 'excel', 'zooming']"
        :isUseCard="false"
        :isFocusRow="true"
        :descriptions="descriptions"
        :isFixedHeader="true"
    >
        <template v-slot:salesName="{ isHeader, row }">
            <div
                v-if="!isHeader"
                :class="`to-${row.salesName.position}`"
                v-html="row.salesName.value"
            />
        </template>
    </Table>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    detailColumns1,
    detailDescriptions1,
    detailPerformanceHelper
} from '@/assets/javascripts/report/setting'

export default {
    name: 'DetailTable1',
    components: {
        Table
    },
    props: {
        bgName: {
            type: String,
            default: ''
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        stylerList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        licenseList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        performanceList: {
            type: Object,
            default: function () {
                return {}
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        infos: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        transformDetail: function (rows) {
            return rows.reduce((memo, row) => {
                const uid = row.uid || '-'
                const manCode = row.manCode || '-'
                const style = this.stylerList[uid]
                const p = this.performanceList[manCode]
                const license = this.licenseList[manCode]

                if (row.sub) {
                    memo.push(...this.transformDetail(row.sub))
                    if (uid === 'root' && memo.length === 0) {
                        return memo
                    }

                    let sumRow = this.getSumColumn(row, row.sub)
                    // 若style = hide時sumRow會是null
                    if (sumRow) {
                        if (Array.isArray(sumRow)) {
                            memo = [...memo, ...sumRow]
                        } else {
                            memo.push(sumRow)
                        }
                    }
                } else {
                    /* end node */
                    if (style?.visible === false) {
                        return memo
                    }
                    // salesName用於轉過HTML的欄位
                    row.salesName = row.name || '-'
                    row.salesName = {
                        position: style?.position || 'center',
                        value: row.name
                    }
                    row.apStuName = row?.apStuName || '-'
                    row.titleTrans = row.titleTrans || '-'
                    row.registerDate = row.date?.substring(0, 10) || '-'
                    row.license = `${license?.property ? '' : '■ '}`
                    row.license += `${license?.investment ? '' : '● '}`
                    row.license += `${license?.foreignCurrency ? '' : '▲'}`

                    row = detailPerformanceHelper(row, p, this.$roundX)

                    // 報表style
                    row = this.getColumnStyle(row, style)
                    if (Array.isArray(row)) {
                        memo = [...memo, ...row]
                    } else {
                        memo.push(row)
                    }
                }
                return memo
            }, [])
        },
        getSumColumn: function (row, detail) {
            const uid = row.uid || '-'
            const style = this.stylerList[uid]
            Object.keys(this.tableColumnConfig).forEach((key) => {
                if (this.tableColumnConfig[key].position === 'right') {
                    row[key] = _.sumBy(detail, (o) => {
                        if (typeof o[key] !== 'number') return 0
                        else return o[key]
                    })
                }
            })

            row.salesName = {
                position: style?.position || 'center',
                value: `${row.name}&nbsp;&nbsp;&nbsp;${row.groupTitle}`
            }
            row.apStuName = row?.apStuName || '-'
            row.titleTrans = row.titleTrans || '-'
            row.license = row.license || '-'
            row.registerDate = row.registerDate || '-'

            // 報表style
            if (style) row = this.getColumnStyle(row, style)
            return row
        },
        getColumnStyle: function (row, style) {
            // 報表style
            row.className = {}
            if (style?.ending) row.className['line-yellow-bottom'] = true
            if (style?.lineTop) row.className['line-top'] = true
            if (style?.lineBottom) row.className['line-bottom'] = true

            if (style?.visible === false) {
                // row.className['hide'] = true
                return null
            }

            let skipRowAbove = Array(style?.skipRowAbove || 0).fill(
                this.dummyColumn
            )
            let skipRowBelow = Array(style?.skipRowBelow || 0).fill(
                this.dummyColumn
            )
            return [...skipRowAbove, row, ...skipRowBelow]
        }
    },
    computed: {
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = [
                {
                    uid: 'root',
                    name: this.bgName,
                    groupTitle: '合計',
                    sub: _.cloneDeep(this.listData)
                }
            ]
            dataset = this.transformDetail(dataset)
            return dataset
        },
        dummyColumn: function () {
            const obj = {}
            Object.keys(this.tableColumnConfig).forEach((col) => {
                obj[col] = { value: ' ', unit: '' }
            })
            return obj
        },
        hasData: function () {
            return this.tableShowData.length > 0 || this.isLoading
        },
        excelFileName: function () {
            const name =
                this.filterLog.bgCode?.label || this.filterLog.manCode?.label
            return `單位業績明細表_傳統版_${name}`
        },
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.filterLog,
                descriptions: this.descriptions,
                infos: this.infos
            }
        }
    },
    data() {
        return {
            tableColumnConfig: detailColumns1,
            descriptions: detailDescriptions1
        }
    }
}
</script>

<style lang="scss" scoped>
.table-wrapper {
    :deep(.table-body) {
        .line-top {
            border-top: 2px $sixth-black solid;
            .divider-right:after {
                height: calc(100% + 4px);
            }
        }

        .line-bottom {
            border-bottom: 2px $sixth-black solid;
            .divider-right:after {
                height: calc(100% + 4px);
            }
        }

        .line-yellow-bottom {
            border-bottom: 2px $third-yellow solid;
            .divider-right:after {
                height: calc(100% + 4px);
            }
        }

        .skip-top {
            margin-top: 50px;
        }

        .skip-bottom {
            margin-bottom: 50px;
        }

        .sales-name {
            div {
                width: 100%;
            }
        }
    }

    // @media screen and (max-width: 576px) {
    //     display: none;
    // }
}
</style>
